/* src/App.css */

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
/* Changed to a bolder weight */

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('/images/crumbled-paper-texture.jpg');
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.container {
    text-align: center;
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.full-paper {
    width: 90%;
    height: 70%;
    transition: opacity 2s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

textarea {
    width: 90%;
    height: 80%;
    border: none;
    outline: none;
    resize: none;
    background: transparent;
    font-size: 50px;
    color: #333;
    padding: 10px;
    font-family: 'Dancing Script', cursive;
    text-align: center;
    font-weight: 700;
    /* Updated font weight for boldness */
}

textarea::placeholder {
    color: #333;
    text-align: center;
}

.controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    height: 6%;
    width: 100%;
    gap: 15px;
    /* Space between buttons */
}

button {
    padding: 12px 20px;
    /* Increased padding for a better feel */
    margin: 5px;
    border: none;
    border-radius: 8px;
    /* Rounded corners */
    background-color: #4caf50;
    /* Green background */
    color: white;
    /* Text color */
    font-size: 18px;
    /* Slightly larger font size */
    font-weight: bold;
    /* Bold text */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    /* Smooth transitions */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for depth */
}

button:hover {
    background-color: #45a049;
    /* Darker shade on hover */
    transform: scale(1.05);
    /* Slightly enlarge on hover */
}

button:active {
    transform: scale(0.95);
    /* Shrink on click */
}


.controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    height: 7%;
    width: 110%;
    gap: 15px;
    /* Space between buttons */
}

button {
    padding: 12px 20px;
    /* Increased padding for a better feel */
    margin: 5px;
    border: none;
    border-radius: 8px;
    /* Rounded corners */
    background-color: #555;
    /* Dark matte black */
    color: #f5f5f5;
    /* Light cream color for text */
    font-size: 15px;
    /* Slightly larger font size */
    font-weight: bold;
    /* Bold text */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    /* Smooth transitions */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    /* Deeper shadow for contrast */
}

button:hover {
    background-color: #3a3a3a;
    /* Slightly lighter black on hover */
    transform: scale(1.05);
    /* Slightly enlarge on hover */
}

button:active {
    transform: scale(0.95);
    /* Shrink on click */
}


.dissolve {
    opacity: 0;
    /* For dissolve effect */
    transition: opacity 5s ease;
    /* Smooth transition for the dissolve effect */
}

/* Add this to your existing App.css */

/* Add this to your existing App.css */

.affirmation {
    position: absolute;
    top: 50%;
    /* Center vertically */
    left: 50%;
    /* Center horizontally */
    transform: translate(-50%, -50%);
    /* Adjust position to be truly centered */
    font-size: 24px;
    /* Size of the affirmation text */
    color: #111;
    /* Color of the text */
    opacity: 0.8;
    /* Slight transparency */
    text-align: center;
    /* Center the affirmation */
    pointer-events: none;
    /* Ensure clicks pass through */
}