body,
html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
}

#root {
    height: 100%;
}